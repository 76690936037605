import { ApiWithToken } from "../axios-instance"
import { MChat } from "../../types/Complaint"

export const complaintsService = {
  chats: {
    getChats: async (): Promise<MChat[]> => {
      const response = await ApiWithToken.get("/api/complaints/get_chats/");
      return response.data;
    },
    updateChatState: async (chatId: number, newState: string): Promise<MChat> => {
      const response = await ApiWithToken.patch(`/api/complaints/chats/${chatId}/update_state/`, {
        state: newState,
      });
      return response.data;
    },
  },
}

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function TawkLoader() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/inicio") {
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://embed.tawk.to/677c53a049e2fd8dfe035aaf/1iguqdat8";
      script.charset = "UTF-8";
      script.setAttribute("crossorigin", "*");
      document.body.appendChild(script);

      const a = document.getElementsByClassName("widget-hidden");
      if (a.length > 0) {
        a[0].className = "widget-visible";
      }

      // Clean up the script when leaving the route
      return () => {
        document.body.removeChild(script);
      };
    } else {
      const a = document.getElementsByClassName("widget-visible");
      if (a.length > 0) {
        a[0].className = "widget-hidden";
      }
    }
  }, [location.pathname]);

  return null;
}

export default TawkLoader;

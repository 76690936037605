import {
  Badge,
  Box,
  Button,
  Flex,
  Paper,
  Stack,
  Table,
  TextInput,
  Title,
} from "@mantine/core";
import { GenderLabAppShell } from "../../components";
import { useEffect, useState } from "react";
import { IconSearch } from "@tabler/icons-react";
import { MChat } from "../../types/Complaint";
import { CDDetails } from "../../components/CanalDenuncias/CDDetails";
import { complaintsService } from "../../api/services/complaints-service";

export const CanalDenunciasPage = () => {
  const [value, setValue] = useState("");
  const [chats, setChats] = useState<MChat[]>([]);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [selectedChat, setSelectedChat] = useState<MChat>();

  useEffect(() => {
    const fetchChats = async () => {
      const chats = await complaintsService.chats.getChats();
      console.log("chats:", chats);
      setChats(chats);
    };

    fetchChats();
  }, []);

  const handleClickRow = (chat: MChat) => {
    setShowDetails(true);
    setSelectedChat(chat);
  };

  const formatDate = (chatDate: string) => {
    const date = new Date(chatDate);
    const localDate = new Date(date.getTime() - 5 * 60 * 60 * 1000);

    const day = String(localDate.getDate()).padStart(2, "0"); // Día
    const month = String(localDate.getMonth() + 1).padStart(2, "0"); // Mes (0-based)
    const year = localDate.getFullYear(); // Año

    const hours = String(localDate.getHours()).padStart(2, "0"); // Horas
    const minutes = String(localDate.getMinutes()).padStart(2, "0"); // Minutos
    const seconds = String(localDate.getSeconds()).padStart(2, "0"); // Segundos

    return `${day}/${month}/${year} a las ${hours}:${minutes}:${seconds}`;
  };

  const isDevelopment = process.env.NODE_ENV;
  console.log(isDevelopment);

  return (
    <GenderLabAppShell>
      {!showDetails ? (
        <div>
          <Title order={3}>Casos denunciados</Title>
          <Title order={4}>Listado de casos desde el más reciente</Title>
          <Stack mt={20}>
            <Flex
              justify="flex-start"
              align="center"
              direction="row"
              wrap="wrap"
              gap="md"
            >
              {["Total de casos denunciados", "Total casos abiertos", "Total casos cerrados"].map(
                (title, index) => (
                  <Paper
                    withBorder
                    p={40}
                    radius={10}
                    style={{ textAlign: "center", flex: "1 1 300px" }}
                    key={title}
                  >
                    <Flex direction="column" align="center" justify="center">
                      <Title order={1}>
                        {index === 2 ? 0 : chats.length}
                      </Title>
                      <Title order={5}>{title}</Title>
                    </Flex>
                  </Paper>
                )
              )}
            </Flex>
            <Box>
              <Flex direction={{ base: "column", sm: "row" }} gap="md">
                <TextInput
                  value={value}
                  onChange={(event) => setValue(event.currentTarget.value)}
                  placeholder="Enter search terms"
                  style={{ flex: 1 }}
                  rightSection={<IconSearch size={18} />}
                />
                <Button
                  component="a"
                  href="http://hola.elsa.la/empresademo"
                  target="_blank"
                  variant="light"
                  color="blue"
                >
                  PORTAL
                </Button>
              </Flex>
            </Box>
            <Paper withBorder p={20} radius={10} style={{ overflowX: "auto" }}>
              <Table striped highlightOnHover withBorder withColumnBorders>
                <thead>
                <tr>
                  <th>Fecha denuncia</th>
                  <th>Descripción</th>
                  <th>Estado</th>
                  <th>Última Actualización</th>
                  <th>Responsable</th>
                </tr>
                </thead>
                <tbody>
                {chats.map((chat) => {
                  return (
                    <tr
                      key={chat.id}
                      onClick={() => handleClickRow(chat)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{formatDate(chat.created_at)}</td>
                      <td>{chat.title}</td>
                      <td>
                        <Badge color="teal">{chat.state}</Badge>
                      </td>
                      <td>{formatDate(chat.updated_at)}</td>
                      <td>Carlos Ortiz</td>
                    </tr>
                  );
                })}
                </tbody>
              </Table>
            </Paper>
          </Stack>
        </div>
      ) : (
        <CDDetails
          setShowDetails={setShowDetails}
          selectedChat={selectedChat}
          formatDate={formatDate}
          setSelectedChat={setSelectedChat}
        />
      )}
    </GenderLabAppShell>
  );
};
